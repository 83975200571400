import React from 'react';
import classNames from 'classnames';
const Client = ({alt, img, width = '', className = ''}) => {
  let _classes =  classNames(`h-auto m-6 ${className}`, {
    'max-w-100': !width,
  }, width);
  
  return <div className="flex items-center mx-2"><img className={_classes} src={img} /></div>
};

  export default Client;