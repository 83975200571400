import React, { useEffect } from "react"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import favIcon from '../images/favicon.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css'; 


const IndexPage = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
      <Hero />
  )
}

export default IndexPage
