import React, {createRef, useEffect, useState} from 'react';
import ContentWrapper from './ContentWrapper';
import ClientSection from './ClientSection';
import { Link } from 'gatsby';
import Section from './Section';
import TeamIllustration from '../images/TeamIllustration.svg';
import Heisann from '../images/titles/heisann.svg';
import VideoFrame from '../images/Ramme.svg';
import PlayIcon from '../images/PlayIcon.svg';
import VimeoPlayer from '@vimeo/player';
import ArrowDown from '../images/arrow_down.svg';

const Hero = () => {
    const videoIframe = createRef();
    let videoPlayer = createRef().current;
    const [playVideo, setPlayVideo] = useState(false);

    useEffect(() => {
        if(videoIframe.current) {
            videoPlayer = new VimeoPlayer(videoIframe.current);
        }
    }, [videoIframe])

    const playOrPauseVideo = () => {
        setPlayVideo(!playVideo);
    }

    useEffect(async () => {
        if(videoIframe.current && videoPlayer) {
            if(playVideo) {
                videoPlayer.play().then(() => {
                   console.log("Playing video")
                }).catch(ex => {
                    console.error(ex);
                });
                /*
                videoPlayer.setVolume(0.6).then(() => {
                    console.log("Set volume");
                }).catch(ex => {
                    console.error(ex);
                });
                */
            } else {
                videoPlayer.pause().then().catch(ex => {
                    console.error(ex);
                });
            }
        }
    }, [playVideo])


    useEffect(() => {
        window.addEventListener('scroll', hideArrowButtonOnScroll);
        return () => {
            window.removeEventListener('scroll', hideArrowButtonOnScroll);
        }
    }, [])

    

    const hideArrowButtonOnScroll = () => {
        if(window.scrollY > 0) {
            document.getElementById("scroll_down_button").style.display = "none";
        }
    }

    const scrollToMainSection = () => {
        const yOffset = -100; 
        const element = document.getElementById("main_section");
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    return <>
    <div className="video-responsive z-0">
        <img src={ArrowDown} id="scroll_down_button" onClick={scrollToMainSection} className="cursor-pointer hidden lg:block scroll-down-button absolute left-2/4 z-50 bottom-60" />
        <iframe src="https://player.vimeo.com/video/708980610?autoplay=1&muted=1&loop=1&title=0&controls=0" allow="autoplay" width="640" height="360" className="sm:height-auto md:mb-32 mx-auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        <div className="video-responsive-overlay flex  items-center">     

            <div className='max-w-md px-8 md:px-24 md:mt-0'>
                <p className="md:hero-text text-white mt-4 font-bold text-xl lg:text-2xl ">
                Produksjonsselskap med fokus på håndtegnet 2D-animasjon
                </p>
                <Link to="https://vimeo.com/708980610" target="_blank" className="w-full flex ">
                    <div className="mt-8 flex  rounded-md border-transparent  text-white items-center justify-center bg-tb-dark cursor-pointer showreel-button">Showreel</div>
                </Link>
            </div>
        </div> 
        
    </div>
    <div  id="main_section">
    <div className='px-8 lg:px-16' >
        <Section className=" mt-8 md:mt-16 block md:flex px-2 avg:px-2 lg:px-32 2xl:px-56 text-tb-dark  justify-center items-end" >
            <div className="team-illustration flex justify-center md:hidden " >
                <img className="team-illustration__title max-h-80 " src={Heisann}  data-aos="fade-in" data-aos-delay="100"/>
                <img src={TeamIllustration} className="max-h-80 mt-4" data-aos="fade-in" />
            </div>
            <div data-aos="fade-up" data-aos-delay="250">
                <p className="text-xl mb-1">Animasjon</p>
                <p className="text-2xl mb-10">Kreative filmer</p>
                <p className="max-w-lg">
                    The Branch er et produksjonsselskap med fokus på håndtegnet 2D-animasjon. 
                    Vi jobber mye med kortfilm og musikkvideo.
                    <br /><br /><br />
                    Vi i The Branch elsker å tegne morsomme karakterer med mye særpreg.
                    <br /><br />
                    Målet er å legge igjen et sterkt inntrykk av seeren som ikke forlater tankene med det første.
                </p>
            </div>
            <div className="team-illustration hidden md:block" >
                <img className="team-illustration__title lg:max-h-80 max-h-56 " src={Heisann}  data-aos="fade-in" data-aos-delay="100"/>
                <img src={TeamIllustration} className="lg:max-h-80 max-h-56 " data-aos="fade-in" />
            </div>
        </Section>
        <ContentWrapper>
            <div className="mt-8 md:mt-32 flex justify-center relative about-video " data-aos="fade-up" data-aos-delay="150">
                <img src={VideoFrame} className="about-video__frame" />
                <iframe ref={videoIframe} className="absolute"  src="https://player.vimeo.com/video/744917874?byline=0&portrait=0&title=0&controls=1&playsinline=1&muted=0&autoplay=0"   frameborder="0" allow="autoplay; encrypted-media; fullscreen; picture-in-picture" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
            </div>
        </ContentWrapper>
        <ContentWrapper>
            <div className="mt-8 md:mt-32 flex justify-center relative about-video " data-aos="fade-up" data-aos-delay="150">
                <img src={VideoFrame} className="about-video__frame" />
                <iframe ref={videoIframe} className="absolute"  src="https://player.vimeo.com/video/926199027?byline=0&portrait=0&title=0&controls=1&playsinline=1&muted=0&autoplay=0"   frameborder="0" allow="autoplay; encrypted-media; fullscreen; picture-in-picture" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
            </div>
        </ContentWrapper>
        <ContentWrapper>
            <ClientSection />
        </ContentWrapper>
    </div>
    </div>
  </>
}

export default Hero;

/*
    <Section gray className="px-8 lg:px-16 justify-center lg:justify-start flex flex-wrap-reverse items-center lg:justify-between">
        <div className="w-full md:w-1/2">
            <p className="font-bold mb-4">Trenger du animasjon?</p>
            <p>Trenger du animasjon for ditt produkt eller tjeneste? Vi tilbyr info - og reklamefilmer, med flytende bevegelser. Produsert raskt og med høy kvalitet! </p>
        </div>
        <img src={bird_1} style={{height: "300px"}} />
    </Section>
    <Section className="px-8 lg:px-16 justify-center lg:justify-start flex flex-wrap items-center lg:justify-between">
        <img src={bird_2} style={{height: "300px"}} />
        <div className="w-full md:w-1/2">
            <p className="font-bold mb-4">Skreddersydd etter dine ønsker</p>
            <p>Hyrer du oss inn til å produsere animasjonsfilm, samarbeider vi om å finne ut best måte å formidle det du ønsker. </p>
        </div>
    </Section>
    <Section gray className="px-8 lg:px-16 justify-center lg:justify-start flex flex-wrap-reverse items-center lg:justify-between">
        <div className="w-full md:w-1/2">
            <p className="font-bold mb-4">Komunikasjon</p>
            <p>Deretter garanterer vi at vi vil produsere er attraktivt, oversiktlig og skreddersydd produkt av høyeste kvalitet.</p>
            <Link to="/contact">
                <div className="mt-8 flex border rounded-lg  text-white items-center justify-center bg-tb-blue cursor-pointer" style={{height: "40px", width: "130px"}}>Kontakt oss</div>
            </Link>
        </div>
        <img src={bird_3} style={{height: "300px"}} />
    </Section>*/